<template>
  <div class="page flex-col">
    <div class="container-body flex-col">
      <!-- 中间部分标语 -->
      <div class="container">
      <div class="row block_3">
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/left-decorative-horizontal-bar.png"/>
          </p>
        </div>
        <div class="col-sm-4">
          <p class="text_6">让你轻松设计网络通信方案</p>
        </div>
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/right-decorative-horizontal-bar.png"/>
          </p>
        </div>
      </div>
      </div>
     
      <div class="block_1 flex-col">
        <div class="block_4 flex-col">
          <div class="text-wrapper_2 flex-col"><span class="text_7">资讯详情</span></div>
          <div class="container">
            <div class="section_5 flex-col">
              <div class="text-wrapper_10 flex-col">
                <!-- <p class="text_1">
                  5G引领时代
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 资讯详情 -->
      <div class="container">
        <div class="text-wrapper_4 flex-col"><span class="text_9">一.我国5G发展现状</span></div>
        <div class="text-wrapper_5">
          <p class="text_10">
            我国移动通信技术起步虽晚，但在5G标准研发上正逐渐成为全球的领跑者。我们国家在1G、2G发展过程中以应用为主，处于引进、跟随、模仿阶段。从3G开始，我们国家初步融入国际发展潮流，大唐集团和西门子共同研发的TD－SCDMA成为全球三大标准之一。在4G研发上，我国已经有了自主研发的TD－LTE系统，并成为全球4G的主流标准。5G方面，我国政府、企业、科研机构等各方高度重视前沿布局，力争在全球5G标准制定上掌握话语权。中国5G标准化研究提案在2016世界电信标准化全会（WTSA16）第6次全会上已经获得批准，这说明我国5G技术研发已走在全球前列。
          </p>
          <!-- <span class="paragraph_1"><br /></span> -->
        </div>
        <p class="paragraph_2">
          政府层面，顶层前沿布局已逐步展开，明确了5G技术突破方向。一是我国从国家宏观层面明确了未来5G的发展目标和方向。《中国制造2025》提出全面突破5G技术，突破“未来网络”核心技术和体系架构；《十三五规划纲要》提出要积极推进5G发展，布局未来网络架构，到2020年启动5G商用。2013年，工信部、发改委和科技部组织成立了“IMT－&nbsp;2020（5G）推进组”（以下简称推进组），推进组负责协调推进5G技术研发试验工作，与欧美日韩等国家建立5G交流与合作机制，推动全球5G的标准化及产业化。推进组陆续发布了《5G愿景与需求白皮书》《5G概念白皮书》等研究成果，明确了5G的技术场景、潜在技术、关键性能指标等，部分指标被ITU纳入到制定的5G&nbsp;需求报告中。二是依托国家重大专项等方式，积极组织推动5G核心技术的突破。国家“973”计划早在2011年就开始布局下一代移动通信系统。2014年国家“863”计划启动了“实施5G移动通信系统先期研究”重大项目，围绕5G核心关键性技术，先后部署设立了11个子课题。2016年“新一代宽带无线移动通信”国家科技重大专项，全面启动了我国5G技术研发试验，目前已完成第一阶段测试，各关键技术均通过验证。
        </p>
        <p class="paragraph_3">
          第五代移动通信技术，英语缩写为5G。与4G相比，5G具有更高的速率、更宽的带宽、更高的可靠性、更低的时延等特征，能够满足未来虚拟现实、超高清视频、智能制造、自动驾驶等用户和行业的应用需求。目前5G还处于规划阶段，概念和技术未形成统一的标准。我国正大力开展5G技术与产业化的前沿布局，在多个领域取得了积极进展，为抢占5G发展先机打下坚实基础。
        </p>
        <!-- 第二段落 -->
        <div class="text-wrapper_4 flex-col"><span class="text_9">二.我国5G发展现状</span></div>
        <div class="text-wrapper_5">
          <p class="text_10">
            我国移动通信技术起步虽晚，但在5G标准研发上正逐渐成为全球的领跑者。我们国家在1G、2G发展过程中以应用为主，处于引进、跟随、模仿阶段。从3G开始，我们国家初步融入国际发展潮流，大唐集团和西门子共同研发的TD－SCDMA成为全球三大标准之一。在4G研发上，我国已经有了自主研发的TD－LTE系统，并成为全球4G的主流标准。5G方面，我国政府、企业、科研机构等各方高度重视前沿布局，力争在全球5G标准制定上掌握话语权。中国5G标准化研究提案在2016世界电信标准化全会（WTSA16）第6次全会上已经获得批准，这说明我国5G技术研发已走在全球前列。
          </p>
          <!-- <span class="paragraph_1"><br /></span> -->
        </div>
        <p class="paragraph_2">
          政府层面，顶层前沿布局已逐步展开，明确了5G技术突破方向。一是我国从国家宏观层面明确了未来5G的发展目标和方向。《中国制造2025》提出全面突破5G技术，突破“未来网络”核心技术和体系架构；《十三五规划纲要》提出要积极推进5G发展，布局未来网络架构，到2020年启动5G商用。2013年，工信部、发改委和科技部组织成立了“IMT－&nbsp;2020（5G）推进组”（以下简称推进组），推进组负责协调推进5G技术研发试验工作，与欧美日韩等国家建立5G交流与合作机制，推动全球5G的标准化及产业化。推进组陆续发布了《5G愿景与需求白皮书》《5G概念白皮书》等研究成果，明确了5G的技术场景、潜在技术、关键性能指标等，部分指标被ITU纳入到制定的5G&nbsp;需求报告中。二是依托国家重大专项等方式，积极组织推动5G核心技术的突破。国家“973”计划早在2011年就开始布局下一代移动通信系统。2014年国家“863”计划启动了“实施5G移动通信系统先期研究”重大项目，围绕5G核心关键性技术，先后部署设立了11个子课题。2016年“新一代宽带无线移动通信”国家科技重大专项，全面启动了我国5G技术研发试验，目前已完成第一阶段测试，各关键技术均通过验证。
        </p>
        <p class="paragraph_3">
          第五代移动通信技术，英语缩写为5G。与4G相比，5G具有更高的速率、更宽的带宽、更高的可靠性、更低的时延等特征，能够满足未来虚拟现实、超高清视频、智能制造、自动驾驶等用户和行业的应用需求。目前5G还处于规划阶段，概念和技术未形成统一的标准。我国正大力开展5G技术与产业化的前沿布局，在多个领域取得了积极进展，为抢占5G发展先机打下坚实基础。
        </p>
        <!-- 第三段落 -->
        <div class="text-wrapper_4 flex-col"><span class="text_9">三.我国5G发展现状</span></div>
        <div class="text-wrapper_5">
          <p class="text_10">
            我国移动通信技术起步虽晚，但在5G标准研发上正逐渐成为全球的领跑者。我们国家在1G、2G发展过程中以应用为主，处于引进、跟随、模仿阶段。从3G开始，我们国家初步融入国际发展潮流，大唐集团和西门子共同研发的TD－SCDMA成为全球三大标准之一。在4G研发上，我国已经有了自主研发的TD－LTE系统，并成为全球4G的主流标准。5G方面，我国政府、企业、科研机构等各方高度重视前沿布局，力争在全球5G标准制定上掌握话语权。中国5G标准化研究提案在2016世界电信标准化全会（WTSA16）第6次全会上已经获得批准，这说明我国5G技术研发已走在全球前列。
          </p>
          <!-- <span class="paragraph_1"><br /></span> -->
        </div>
        <p class="paragraph_2">
          政府层面，顶层前沿布局已逐步展开，明确了5G技术突破方向。一是我国从国家宏观层面明确了未来5G的发展目标和方向。《中国制造2025》提出全面突破5G技术，突破“未来网络”核心技术和体系架构；《十三五规划纲要》提出要积极推进5G发展，布局未来网络架构，到2020年启动5G商用。2013年，工信部、发改委和科技部组织成立了“IMT－&nbsp;2020（5G）推进组”（以下简称推进组），推进组负责协调推进5G技术研发试验工作，与欧美日韩等国家建立5G交流与合作机制，推动全球5G的标准化及产业化。推进组陆续发布了《5G愿景与需求白皮书》《5G概念白皮书》等研究成果，明确了5G的技术场景、潜在技术、关键性能指标等，部分指标被ITU纳入到制定的5G&nbsp;需求报告中。二是依托国家重大专项等方式，积极组织推动5G核心技术的突破。国家“973”计划早在2011年就开始布局下一代移动通信系统。2014年国家“863”计划启动了“实施5G移动通信系统先期研究”重大项目，围绕5G核心关键性技术，先后部署设立了11个子课题。2016年“新一代宽带无线移动通信”国家科技重大专项，全面启动了我国5G技术研发试验，目前已完成第一阶段测试，各关键技术均通过验证。
        </p>
        <p class="paragraph_3">
          第五代移动通信技术，英语缩写为5G。与4G相比，5G具有更高的速率、更宽的带宽、更高的可靠性、更低的时延等特征，能够满足未来虚拟现实、超高清视频、智能制造、自动驾驶等用户和行业的应用需求。目前5G还处于规划阶段，概念和技术未形成统一的标准。我国正大力开展5G技术与产业化的前沿布局，在多个领域取得了积极进展，为抢占5G发展先机打下坚实基础。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InformationDetails",
  data() {
    return {
      menuIndex: 3,
      constants: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 10,
        pageTotal: 50
      },
    };
  },
  created() {
    localStorage.setItem("menuIndex", this.menuIndex);
  },
  methods: {
    
  }
};
</script>
<style scoped lang="css" src="../../assets/informationDetails.css" />